.vjs-party-icons {
    visibility: hidden
}

.vjs-svg-icon {
    display: flex;
    background-repeat: no-repeat;
    background-position: center;
    fill: #fff;
    height: 20px;
    width: 20px;
    margin: 0 auto;

    svg {
        vertical-align: unset;
    }
}

.vjs-svg-icon:before {
    content: none !important;
}

.vjs-1600 .vjs-control-bar .vjs-control .vjs-svg-icon {
    height: 27px;
    width: 27px;
}

.video-js:active, .video-js:focus {
    outline: none;
    border: 0;
}

*, *:before, *:after {
    box-sizing: border-box;
}

.vjs-playback-rate {
    display: none
}

.fs-hidden {
    visibility: hidden;
    width: 0 !important
}

.no-select {
    user-select: none;
}

.vjs-skin {
    visibility: hidden;
    height: 0
}

.vjs-skin:before {
    content: "party"
}

.video-js {
    background-color: #000;
    box-sizing: border-box;
    display: block;
    color: #fff;
    outline: 0;
    position: relative;
    overflow: hidden;
    padding: 0;
    user-select: none;
    font-family: Arial, sans-serif;
    font-size: 14px;
}

.video-js .disabled {
    pointer-events: none;
}

.vjs-brightness {
    position: absolute;
    height: 35%;
    transform: translateY(-50%);
    top: 50%;
    opacity: 0;
    left: 0;
    width: 50px;
    max-height: 250px;
}


.vjs-brightness .vjs-svg-icon {
    margin-top: -35px;
    fill: #fff;
}

.vjs-ended .vjs-brightness {
    opacity: 0;
    pointer-events: none;
}

.vjs-480 .vjs-brightness {
    display: none;
}

.vjs-has-started.vjs-touch-active .vjs-brightness {
    opacity: 1;
    pointer-events: auto;
    z-index: 112;
}

.vjs-brightness .vjs-brightness-bar {
    height: 100%;
    width: 100%;
}

.vjs-brightness .vjs-brightness-bar .bar {
    width: 4px;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: #888;
    transform: translateX(-50%);
    left: 50%;

}

.vjs-brightness .vjs-brightness-bar .bar-level {
    width: 4px;
    height: 50%;
    position: absolute;
    bottom: 0;
    background-color: #fff;
    transform: translateX(-50%);
    left: 50%;
}

.vjs-icon-placeholder, .vjs-control-text {
    pointer-events: none;
}

.vjs-spacer {
    display: none;
}

.video-js .vjs-picture-in-picture-control, .video-js .vjs-pip-control {
    cursor: pointer;
    flex: none;
}

.vjs-disabled {
    cursor: none;
}

.video-js .vjs-loading-spinner {
    display: none;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 82px;
    height: 82px;
    margin-top: -41px;
    margin-left: -41px;
    border-radius: 50%;
    border: 3px solid #fff;
    border-top-color: rgba(255, 255, 255, 0.75);
    animation: vjs-spinner-show 1s linear infinite;

}

.vjs-seeking .vjs-loading-spinner, .vjs-waiting .vjs-loading-spinner {
    display: block;
}

.vjs-scrubbing .vjs-control-bar {
    opacity: 1;
    visibility: visible;
}

.video-js .vjs-block {
    display: block;
}

.vjs-marker {
    position: absolute;
    top: 12px;
    padding: 0 5px;
    margin-left: -7px;
}

.vjs-marker-inn {
    width: 2px;
    height: 8px;
    background-color: #ccc;

}

.vjs-has-started.vjs-user-active .vjs-nav-next, .vjs-has-started.video-js.vjs-user-active .vjs-nav-prev {
    display: table;
}

.video-js.vjs-16-9,
.video-js.vjs-9-16,
.video-js.vjs-1-1,
.video-js.vjs-4-3,
.video-js.vjs-fluid {
    height: 0;
    max-width: 100%;
    width: 100%
}

.video-js.vjs-fluid:not(.vjs-audio-only-mode),
.video-js.vjs-16-9:not(.vjs-audio-only-mode),
.video-js.vjs-4-3:not(.vjs-audio-only-mode),
.video-js.vjs-9-16:not(.vjs-audio-only-mode),
.video-js.vjs-1-1:not(.vjs-audio-only-mode) {
    height: 0;
}

.video-js.vjs-16-9 {
    padding-top: 56.25%
}

.video-js.vjs-4-3 {
    padding-top: 75%
}

.video-js.vjs-9-16:not(.vjs-audio-only-mode) {
    padding-top: 177.7777777778%;
}

.video-js.vjs-1-1:not(.vjs-audio-only-mode) {
    padding-top: 100%;
}

.video-js.vjs-fill:not(.vjs-audio-only-mode) {
    width: 100%;
    height: 100%;
}

.video-js .vjs-tech {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    display: inline-block;
    outline: 0;

}

.vjs-touch-active .vjs-tech {
    opacity: .7;
}

body.vjs-full-window {
    height: 100%;
    margin: 0;
    padding: 0;

}

.vjs-full-window, .video-js.vjs-fullscreen {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999999;
    padding-top: 0 !important;
}


.video-js.vjs-fullscreen {
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding-top: 0 !important;
}

.video-js.vjs-fullscreen.vjs-user-inactive {
    cursor: none;
}

.video-js .vjs-close-btn {
    color: #fff;
    cursor: pointer;
    position: absolute;
    top: 6px;
    right: 10px;
    line-height: 1;
    outline: none;
    border: solid 2px transparent;
}

.video-js .vjs-close-btn .vjs-svg-icon {
    width: 24px;
    height: 24px;
    fill: #fff;
}

.video-js .vjs-zoom-parent {
    position: absolute;
    left: 0;
    top: 0;
    height: 30px;
    line-height: 30px;
    background: #222;

}

.vjs-zoom-parent .vjs-svg-icon {
    width: 18px;
    height: 30px;
    fill: #fff;
}

.vjs-zoom-parent .vjs-reset-zoom {
    float: left;
    padding: 0 5px;
}

.vjs-zoom-parent .vjs-reset-center .vjs-svg-icon {
    width: 20px;
}

.vjs-reset-info, .vjs-reset-center, .vjs-reset-cancel {
    padding: 0 7px;
    color: #fff;
    float: left;
    font-size: 18px;
    cursor: pointer;
    display: inline-block;
    height: 100%;
    text-shadow: 1px 1px 1px #000
}

.video-js .btn-reset-zoom {
    position: absolute;
    left: 0;
    bottom: 55px;
    color: #fff;
    background: rgba(0, 0, 0, 0.6);
    padding: 5px;
    cursor: pointer;
}

.video-js .btn-reset-zoom:hover {
    background: #00b1ff;
    text-shadow: 1px 1px 1px #000;
}

.video-js .vjs-zoom-hidden {
    left: -200px;
}

.video-js button:focus, .video-js button:hover, .video-js .vjs-button:focus, .video-js .vjs-button:hover, .video-js .vjs-menu-item:focus, .video-js .vjs-menu-item:hover {
    outline: none;
}

.vjs-has-started.vjs-user-inactive.vjs-playing:not(.vjs-ad-playing) .vjs-logo-min {
    visibility: hidden;
    opacity: 0;
}

.video-js .vjs-logo {
    position: absolute;
    visibility: visible;
    width: 20%;
    height: 20%;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.8;
    z-index: 97;
}

.video-js .vjs-logo-bottom {
    bottom: 10px;
    transition: bottom .5s ease;
}

.vjs-has-started.vjs-user-active .vjs-logo-bottom, .vjs-has-started.vjs-paused .vjs-logo-bottom {
    bottom: 50px;
}

.video-js .vjs-remaining-time-display {
    display: none;
    margin-left: 5px;
}

.video-js .vjs-control-bar .vjs-button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    width: 30px;
}

.video-js .vjs-button-vr .vjs-svg-icon {
    fill: #fff
}

.vjs-control-bar .vjs-control:hover .vjs-control-text {
    visibility: visible;
    opacity: 1;
    top: -35px;
}

.video-js .vjs-control-bar .vjs-control-text, .video-js .vjs-big-play-button .vjs-control-text {
    border: 0;
    margin: 0;
    position: absolute;
    z-index: 2;
    display: none;
}

.vjs-has-mouse .vjs-control-bar .vjs-control-text, .vjs-has-mouse .vjs-big-play-button .vjs-control-text {
    display: block;
    border: 0;
    clip: auto;
    top: -10px;
    background-color: #e5e5e5;
    color: #222;
    padding: 0 5px;
    line-height: 22px;
    font-size: 12px;
    left: 50%;
    transform: translate(-50%, 0);
    white-space: nowrap;
    transition: opacity .3s, top .3s;
    visibility: hidden;
    opacity: 0;
    width: auto;
    z-index: 2;
    border-radius: 2px;
    text-shadow: none;
    font-family: Arial, sans-serif;
}

.vjs-control-bar .vjs-control-text:after, .vjs-big-play-button .vjs-control-text:after {
    content: "";
    display: block;
    background-color: #e5e5e5;
    top: 100%;
    position: absolute;
    left: 50%;
    height: 14px;
    width: 14px;
    border-radius: 1px;
    transform-origin: 75% 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    z-index: -1;
}

.vjs-control-bar .vjs-play-control .vjs-control-text {
    left: 5px;
    transform: translate(0, 0);
}

.vjs-control-bar .vjs-play-control .vjs-control-text:after {
    left: 5px;
    transform: translate(0, -50%) rotate(45deg)
}

.vjs-control-bar .vjs-fullscreen-control .vjs-control-text {
    left: auto;
    transform: translate(0, 0);
    right: 5px;
}

.vjs-control-bar .vjs-fullscreen-control .vjs-control-text:after {
    left: auto;
    right: 6px;
    transform: translate(0, -50%) rotate(45deg);
}

.vjs-control-bar .vjs-volume-level .vjs-control-text, .vjs-menu .vjs-control-text, .vjs-load-progress .vjs-control-text, .vjs-time-control .vjs-control-text, .vjs-progress-control .vjs-control-text {
    display: none;
}

.video-js .vjs-control-bar {
    background-color: transparent;
    bottom: 5px;
    height: 40px;
    left: 1%;
    position: absolute;
    right: 0;
    width: 98%;
    display: none;
    z-index: 5;
}

.vjs-waiting:not(.vjs-seeking):not(.vjs-playing):not(.vjs-casting) .vjs-control-bar {
    display: none;
}

.video-js .vjs-control-bar .vjs-button {
    line-height: 32px;
    width: 38px;
    padding: 0;
    text-align: center;
    color: #fff;
    outline: 0;
    height: 32px;
}

.video-js .vjs-control-bar .vjs-control {
    position: relative;
    flex: none;
    background-color: rgba(0, 0, 0, 0.4);
    margin-top: 8px;
}

.video-js .vjs-control-bar .control-left {
    display: inline-block;
    width: 6px;
    height: 32px;
    background: rgba(0, 0, 0, 0.4);
    margin-top: 8px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

}

.video-js .vjs-control-bar .vjs-progress-control {
    flex: auto;
    display: flex;
    margin: 8px 0 0 0;
    background-color: rgba(0, 0, 0, 0.4);
    height: 32px;
    padding: 0 10px;
    cursor: pointer;
}

.video-js .vjs-progress-holder {
    flex: auto;
    height: 100%;
    position: relative;
    outline: none;
    user-select: none;
}

.video-js .vjs-progress-holder:before {
    content: "";
    background: rgba(0, 192, 255, 0.2);
    top: 12px;
    height: 8px;
    width: 100%;
    position: absolute;
    left: 0;
}

.video-js .vjs-progress-control .vjs-load-progress, .vjs-progress-control .vjs-play-progress {
    height: 8px;
    border: 0;
    left: 0;
    width: 0;
    position: absolute;
    top: 12px;
}

.video-js .vjs-progress-control .vjs-load-progress {
    background-color: rgba(0, 192, 255, 0.2);
}

.video-js .vjs-progress-control .vjs-play-progress {
    background-color: #00b1ff;
}

.video-js .vjs-time-tooltip {
    position: relative;
    float: right;
    top: -3em;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 5px;
    border-radius: 0.3em;
    font-size: 0.8em;
    pointer-events: none;
    visibility: hidden;
    font-family: Arial, sans-serif;
    z-index: 1;
    line-height: 1em;
}


.video-js .vjs-progress-holder:focus .vjs-time-tooltip {
    display: none;
}

.video-js .vjs-progress-control:hover .vjs-time-tooltip,
.video-js .vjs-progress-control:hover .vjs-progress-holder:focus .vjs-time-tooltip {
    display: block;
    font-size: 0.8em;
    visibility: visible;
}

.vjs-error .vjs-time-tooltip {
    display: none;
}

.vjs-has-started .vjs-time-tooltip {
    display: block;
}

.video-js .vjs-control-bar {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.1s, opacity 0.1s;
}

.vjs-has-started .vjs-control-bar {
    display: flex;
}

.vjs-has-started.vjs-user-inactive.vjs-playing:not(.vjs-touch-active):not(.vjs-ad-playing):not(.vjs-casting):not(.vjs-audio) .vjs-control-bar {
    opacity: 0;
    visibility: visible;
    pointer-events: none;
    transition: opacity 0.3s;
}

.video-js .vjs-big-play-button {
    background-color: #00b1ff;
    border-radius: 50%;
    height: 86px;
    left: 50%;
    margin: -43px 0 0 -43px;
    position: absolute;
    top: 50%;
    width: 86px;
    z-index: 3;
    cursor: pointer;
    display: none;
    visibility: visible;
    opacity: 1;
    border: 2px solid #fff;
    z-index: 7;
}

.video-js .vjs-big-play-button .vjs-svg-icon {
    width: 60px;
    height: 60px;
    fill: #fff;
}


.video-js .vjs-replay-button {
    color: #fff;
    left: 50%;
    margin: -50px 0 0 -50px;
    position: absolute;
    top: 50%;
    cursor: pointer;
    background: transparent;
    border: 0;
    display: none;
    width: auto;
}

.video-js .vjs-replay-button .vjs-svg-icon {
    width: 80px;
    height: 80px;
    fill: #fff;
}

.vjs-ended:not(.vjs-vr):not(.vjs-up-next) .vjs-replay-button {
    display: block;
}

.video-js .vjs-touch-nav {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    transform: translate(-50%, -50%);
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: visibility .4s, opacity 0.4s ease;
}

.video-js .vjs-big-button {
    width: auto;
    cursor: pointer;
    display: inline-block;
    padding: 0 25px;
    background: transparent;
    color: #fff;
    border: 0;
}

.vjs-640 .vjs-big-button, .vjs-920 .vjs-big-button {
    padding: 0 40px;
}

.video-js .vjs-b-r-b .vjs-svg-icon, .video-js .vjs-b-f-b .vjs-svg-icon {
    width: 45px;
    height: 45px;
    fill: #fff;
}

.vjs-480 .vjs-b-r-b .vjs-svg-icon, .vjs-480 .vjs-b-f-b .vjs-svg-icon {
    width: 40px;
    height: 40px;
}

.video-js .vjs-b-p-b .vjs-svg-icon {
    width: 60px;
    height: 60px;
    fill: #fff;
}

.vjs-480 .vjs-b-p-b .vjs-svg-icon {
    width: 50px;
    height: 50px;
}

.vjs-has-started .vjs-big-play-button {
    pointer-events: none;
    opacity: 0;
    display: none;
}

.vjs-scrubbing .vjs-loading-spinner, .vjs-scrubbing .vjs-brightness, .vjs-scrubbing .vjs-touch-nav {
    display: none;
}

.vjs-touch-active .vjs-tech {
    opacity: 0.7;
}

.vjs-filters-on .vjs-touch-nav {
    display: none;
}

.vjs-filters-on .vjs-brightness, .vjs-filters-on .vjs-control-bar {
    opacity: 0 !important;
    pointer-events: none;
}

.vjs-more-touch .vjs-brightness, .vjs-more-touch .vjs-touch-nav {
    display: none;
}

.vjs-has-started.vjs-touch-active:not(.vjs-seeking):not(.vjs-ended):not(.vjs-vr) .vjs-touch-nav {
    pointer-events: auto;
    opacity: 1;
    visibility: visible;
}

.vjs-vr .vjs-b-r-b, .vjs-live .vjs-b-r-b, .vjs-ended .vjs-b-r-b,
.vjs-vr .vjs-b-f-b, .vjs-live .vjs-b-f-b, .vjs-ended .vjs-b-f-b {
    display: none;
}

.vjs-touch-active .vjs-control-bar {
    opacity: 1;
    visibility: visible;
}

.vjs-touch-inactive:not(.vjs-ad-playing):not(.vjs-audio):not(.vjs-has-mouse) .vjs-control-bar {
    opacity: 0;
    visibility: hidden;
}

.video-js .vjs-time-control {
    font-size: 12px;
    line-height: 32px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.4);
    margin-top: 8px;
    height: 32px;

}

.video-js .vjs-time-divider:before {
    content: '/';
}

.video-js .vjs-time-divider {
    text-align: center;
    width: 8px;
}

.video-js .vjs-time-divider div {
    display: none;
}

.video-js .vjs-menu {
    background-color: #333;
    background-color: rgba(0, 0, 0, 0.6);
    font-family: Arial, sans-serif;
    min-width: 30px;
}

.video-js .vjs-mute-control:not( :hover ) .vjs-control-text {
    opacity: 0;
}

.video-js .vjs-volume-panel {
    position: relative;
    vertical-align: top;
}

.video-js .vjs-volume-panel .vjs-volume-horizontal {
    background-color: transparent;
    float: right;
    height: 32px;
    line-height: 32px;
    margin-top: 0;
    cursor: pointer;
    margin: 0 4px;
    display: none;
}

.vjs-has-mouse .vjs-volume-panel .vjs-volume-horizontal {
    display: inline-block;
}

.video-js .vjs-volume-panel .vjs-volume-horizontal .vjs-slider-horizontal {
    margin-right: 7px;
    color: #fff;
    width: 20px;
    height: 32px;
    line-height: 3;

}

.video-js .vjs-volume-panel .vjs-volume-horizontal .vjs-slider-horizontal:before {
    content: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 512 512"><path d="M-2.2 312.6H55v115.3H-2.2V312.6zM112.7 255.5h57V428h-57V255.5zM227.2 198.1h57.5V428h-57.5V198.1zM342 141.2h57.1V428h-57V141.2zM456.7 83.8h57.5v344.1h-57.5V83.8z"></path></svg>');
}

.video-js .vjs-volume-panel .vjs-volume-level:before {
    content: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" fill="deepskyblue" viewBox="0 0 512 512"><path d="M-2.2 312.6H55v115.3H-2.2V312.6zM112.7 255.5h57V428h-57V255.5zM227.2 198.1h57.5V428h-57.5V198.1zM342 141.2h57.1V428h-57V141.2zM456.7 83.8h57.5v344.1h-57.5V83.8z"></path></svg>');
    width: 20px;
    display: inline-block;
}

.video-js .vjs-volume-panel .vjs-volume-level {
    position: absolute;
    left: 0;
    width: 20px;
    height: 32px;
}


.video-js .vjs-volume-panel .vjs-volume-horizontal .vjs-slider .vjs-volume-level {
    height: 100%;
    width: 100%;
    top: 0;
    overflow: hidden;
}


.video-js .vjs-volume-panel .vjs-vol-3, .video-js .vjs-volume-panel .vjs-vol-1, .video-js .vjs-volume-panel .vjs-vol-0, .video-js .vjs-volume-panel .vjs-vol-2 {
    margin-top: 0;
    background-color: transparent;
}


.video-js .vjs-menu-settings {
    bottom: 32px;
    cursor: default;
    right: -15px;
    position: absolute;
    width: auto;
    z-index: 1;
    color: #fff;
    background-color: rgba(0, 0, 0, .75);
    font-family: Arial, sans-serif;
    transform: scale(0);
    transform-origin: bottom right;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}

.video-js .vjs-menu-settings .vjs-menu-div {
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    transition: width .25s cubic-bezier(0.4, 0.0, 0.2, 1), height .25s cubic-bezier(0.4, 0.0, 0.2, 1);
}

.video-js .vjs-invisible {
    visibility: hidden;
}


.video-js .vjs-menu-settings .vjs-menu-content {
    text-align: left;
    margin: 0;
    padding: 0;
    font-size: 13px;
    overflow: hidden;
}

.video-js .vjs-menu-settings .vjs-menu-content ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.video-js .vjs-menu-settings .vjs-menu-content li {
    height: 32px;
    line-height: 32px;
    padding: 0 15px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    display: flex;
    font-weight: normal;
    font-family: Arial, sans-serif;
    font-size: 13px;
    margin: 0;
}

.video-js .vjs-menu-settings .vjs-menu-content li:focus {
    outline: none;
}

.video-js .vjs-menu-settings .vjs-menu-content li span {
    align-items: center;
    margin-left: auto;
    overflow: hidden;
    padding-left: 40px;
    padding-right: 10px;
    pointer-events: none;
    display: flex;
    position: relative;
}

.video-js .vjs-menu .vjs-menu-content .vjs-selected {
    background-color: #e5e5e5;
    color: #222;
}

.video-js .vjs-menu .vjs-menu-content .vjs-texttrack-settings, .video-js .vjs-menu .vjs-menu-content .vjs-texttrack-settings:hover {
    background-color: #00b1ff;
    color: #fff;
}

.video-js .vjs-menu-settings .vjs-menu-content .vjs-speed {
    padding-left: 35px;
    padding-right: 15px;
}

.video-js .vjs-menu-settings .vjs-menu-content .vjs-first {
    margin-top: 5px;
}

.video-js .vjs-menu-content .vjs-checked:before {
    position: absolute;
    content: "L";
    font-family: sans-serif, Arial;
    font-weight: bold;
    display: inline-block;
    left: 10px;
    transform: scaleX(-1) rotate(-40deg);
}

.video-js .vjs-menu-settings .vjs-menu-content .vjs-svg-icon {
    display: inline-block;
    height: 26px;
    width: 17px;
    padding: 0;
    margin: 0;
    filter: none;
    fill: #fff;
}

.video-js .vjs-menu-settings .vjs-menu-content li:hover .vjs-svg-icon {
    fill: #222;
}


.video-js .vjs-menu-settings .vjs-menu-content li:after {
    border: 4px solid transparent;
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.video-js .vjs-cog-button .vjs-menu-settings .vjs-menu-content li .vjs-icon-placeholder {
    transform: none;
}

.video-js .vjs-menu-settings .vjs-menu-content .vjs-menu-forward:after {
    right: 5px;
    border-left-color: #fff;
}

.video-js .vjs-menu-settings .vjs-menu-content:hover .vjs-menu-forward:hover:after {
    border-left-color: #222;
}

.video-js .vjs-menu-settings .vjs-settings-back, .video-js .vjs-menu-settings .vjs-settings-back:hover {
    background-color: #00b1ff;
    color: #fff;
    padding: 0 8px 0 20px;
    position: relative;
    font-size: 13px;
    line-height: 30px;
    height: 30px;
    white-space: nowrap;
    cursor: pointer;
    text-align: left;
}


.video-js .vjs-menu-settings .vjs-settings-back:before {
    border: 4px solid transparent;
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
    border-right-color: #fff;
}


.vjs-has-mouse .vjs-menu-settings .vjs-menu-content li:hover {
    background-color: #e5e5e5;
    color: #222;
}

.video-js .vjs-menu-settings .vjs-menu-content .quality-label .vjs-hd-icon {
    margin-top: -12px;
    margin-left: 0;
}

.video-js .vjs-zoom-slide {
    position: absolute;
    top: 15px;
    right: 0;
    width: 40px;
    background: transparent;
    display: none;
}

.vjs-zoom-slide .zoom-bg, .vjs-zoom-slide .zoom-thumb {
    position: absolute;
    width: 4px;
    left: 50%;
    margin-left: -2px;
    top: 0;
    height: 100%;
    background: rgba(255, 255, 255, .5);
}

.vjs-zoom-slide .zoom-thumb {
    background: #fff;
    top: auto;
    bottom: 0;
    height: 0;
}

.vjs-has-started.vjs-user-active .vjs-zoom-slide, .vjs-has-started.vjs-paused .vjs-zoom-slide {
    display: block;
}

.vjs-slide-block {
    display: block;
}


.video-js .vjs-zoom-menu {
    background-color: rgba(0, 0, 0, 0.5);
}

.video-js .vjs-return {
    margin-bottom: 15px;
}

.video-js .vjs-zoom-menu .vjs-zoom-slider {
    height: 70px;
    position: relative;
    display: table;
    margin: 0 auto 12px;
    width: 100%;
    border: solid 2px transparent;
    outline: 0;
}

.video-js .vjs-zoom-menu .vjs-zoom-back {
    background: rgba(0, 177, 255, 0.3);
    width: 6px;
    margin-left: -3px;
    bottom: 0;
    position: absolute;
    cursor: pointer;
    height: 100%;
    left: 50%;
}

.video-js .vjs-zoom-menu .vjs-zoom-level {
    background: #00b1ff;
    background-attachment: scroll;
    bottom: 1px;
    position: absolute;
    width: 6px;
    margin-left: -3px;
    border: solid 1px transparent;
    border-bottom: 0;
    border-top: 0;
    cursor: pointer;
    left: 50%;
}

.video-js .vjs-zoom-menu .vjs-zoom-level:before {
    background: #fff;
    border-radius: 8px;
    content: '';
    height: 14px;
    position: absolute;
    right: -5px;
    top: -6px;
    width: 14px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.66);
}

.video-js .vjs-zoom-menu .vjs-zoom-reset {
    font-size: 11px;
    text-align: center;
    width: 100%;
    height: 25px;
    line-height: 25px;
    cursor: pointer;
    padding: 0 4px;
    white-space: nowrap;
    outline: 0;
}

.video-js .vjs-zoom-menu .vjs-zoom-reset:hover {
    background-color: #00b1ff;
}

.video-js .vjs-hd {
    color: #fff;
    font-weight: bold;
    font-size: 8px;
    font-style: normal;
    padding: 0 1px;
    background: #c00;
    position: absolute;
    top: 4px;
    right: 4px;
    pointer-events: none;
    line-height: normal;
}

.vjs-has-mouse .vjs-menu-settings .vjs-menu-content li:hover .vjs-hd-icon {
    background: #ff0000;
}

.auto-res i {
    font-size: 0.9em;
    padding-left: 4px;
    font-style: normal
}

.vjs-poster {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    opacity: 1;
    transition: opacity 0.5s ease 0s;
}

.vjs-poster img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
}

.vjs-title-bar {
    background: rgba(0, 0, 0, 0.9);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.7) 60%, rgba(0, 0, 0, 0) 100%);
    font-size: 1.2em;
    line-height: 1.5;
    transition: opacity 0.1s;
    padding: 0.666em 1.333em 4em;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
}

.vjs-title-bar-title {
    font-weight: bold;
    margin-bottom: 0.333em;
}

.vjs-title-bar-title, .vjs-title-bar-description {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.vjs-no-pointer {
    pointer-events: none;
}

.vjs-has-started .vjs-poster {
    opacity: 0;
    pointer-events: none;
}

.vjs-audio:not(.vjs-ad-playing) .vjs-poster {
    pointer-events: auto;
}

.vjs-poster-on, .video-js audio {
    opacity: 1;
}

.video-js.vjs-fullscreen.vjs-user-inactive .vjs-poster {
    cursor: none;
}

.vjs-thumbnail-holder {
    position: absolute;
    overflow: hidden;
    left: 0;
    bottom: 33px;
    border: solid 1px #fff;
    transform: scale(0.7);
    transform-origin: 50% 100%;
    transition: transform .3s ease, opacity .3s ease;
    opacity: 0;
    pointer-events: none;
}

/* the thumbnail image itself */
.vjs-thumb-image {
    position: absolute;
    left: 0;
}

.vjs-sld {
    bottom: 28px;
}

.vjs-vtt {
    bottom: 64px;
}

.vjs-thumb-tooltip {
    text-align: center;
    bottom: 4px;
    color: #fff;
    padding: 2px 3px;
    position: absolute;
    display: table;
    font-size: 12px;
    transform: translateX(-50%);
    margin-left: 50%;
    text-shadow: 1px 1px 1px #000;
}

.vjs-thumb-hidden {
    opacity: 0;
}

/* ... but hide the thumbnail when hovering directly over it */
.vjs-progress-control:active .vjs-thumb-image:active {
    opacity: 0;
}


.video-js .vjs-live-control {
    flex: auto;
    display: flex;
    line-height: 32px;
    width: 100%;
    height: 32px;
    margin-top: 8px;
    padding: 0 10px;
    background: rgba(0, 0, 0, 0.4);
}

.video-js .vjs-live-display:before {
    content: '';
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #999;
    display: inline-block;
    margin-right: 6px;
}

.vjs-playing .vjs-live-display:before {
    background-color: #ff0000;
}

.video-js .vjs-control-bar .vjs-transcript-button .vjs-svg-icon {
    fill: #fff;
    stroke: #fff;
    stroke-width: .7;
}

.video-js .vjs-control-bar .vjs-airplay-button .vjs-svg-icon {
    fill: #fff;
    stroke: #fff;
    stroke-width: 6;
    margin-top: -1px;
}

.video-js .vjs-menu .vjs-icon-placeholder:before {
    content: "";
}

.video-js .vjs-play-control .vjs-icon-placeholder:before {
    transform: none;
    line-height: 40px;
}

.video-js .vjs-play-control .vjs-svg-icon {
    width: 32px;
    height: 32px;
}

.vjs-cog-button .vjs-icon-placeholder {
    transform: rotate(0);
    transition: transform .3s;
    display: block;
}

.vjs-cog-active .vjs-icon-placeholder {
    transform: rotate(45deg);
}

.video-js .vjs-current-time {
    padding: 0 5px;
}

.video-js .vjs-duration {
    padding: 0 5px;
}

.video-js .vjs-control-bar .vjs-play-control {
    height: 40px;
    line-height: 40px;
    bottom: 13px;
    width: 55px;
    top: 0;
    border-radius: 4px;
    background-color: #00b1ff;
    font-size: 1.3em;
    margin-top: 0;
    margin-right: 8px;

}

.video-js .vjs-control-bar .vjs-quality-button {
    line-height: 28px;
    min-width: 32px;
    width: auto;
    text-align: center;
    white-space: nowrap;
    border: solid 2px transparent;
}

.video-js .vjs-quality-button span {
    font-size: 12px;
    padding: 0 4px;
}

.video-js .vjs-control-bar .vjs-menu {
    font-family: Arial, sans-serif;
    position: absolute;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.75);
    bottom: 32px;
    color: #fff;
    display: block;
    right: -15px;
    margin: 0;
    padding-bottom: 0;
    width: auto;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    transform-origin: bottom right;
    transition-property: opacity, transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}

.video-js .vjs-menu-button .vjs-hidden {
    display: block;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
}

.video-js .autores {
    font-size: 0.9em;
    padding-left: 4px;
    font-style: normal
}

.video-js .vjs-control-bar .vjs-lock-showing {
    display: block;
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.video-js .vjs-menu .vjs-menu-content {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 13px;
    font-family: Arial, sans-serif;
    text-align: left;
    line-height: normal;
    white-space: nowrap;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
}

.video-js .vjs-menu .vjs-menu-content li {
    line-height: 32px;
    padding: 0 15px;
    font-weight: normal;
    font-family: Arial, sans-serif;
    font-size: 13px;
    margin: 0;
}

.vjs-has-mouse .vjs-menu .vjs-menu-content li:hover {
    background-color: #ccc;
    color: #222;
}

.vjs-has-mouse .vjs-menu .vjs-menu-content li:hover:before {
    background-color: #ccc;
    color: #222;
}

.video-js .vjs-menu .vjs-menu-content li:focus {
    outline: none;
}

.video-js .vjs-menu .vjs-menu-content .item-quality, .video-js .vjs-menu-settings .vjs-menu-content .item-quality {
    padding: 2px 8px 2px 32px;
    position: relative;
}

.video-js .vjs-menu .vjs-menu-content .item-quality i, .video-js .vjs-menu-settings .vjs-menu-content .item-quality i {
    padding: 0 0 0 8px;
    font-size: 11px;
    font-style: normal;
    font-weight: normal;
    pointer-events: none;
}

.video-js .vjs-menu .vjs-menu-content .item-quality .vjs-hd-icon, .video-js .vjs-menu-settings .vjs-menu-content .item-quality .vjs-hd-icon {
    border: 0;
    margin-left: -1px;
    margin-top: 0;
    color: #fff;
    font-weight: bold;
    font-size: 8px;
    height: 10px;
    font-family: Arial, sans-serif;
    padding: 1px;
    pointer-events: none;
}

.video-js .vjs-hd-icon {
    font-family: sans-serif;
    display: inline-block;
    color: #fff;
    font-style: normal;
    font-size: 8px;
    background: #cc0000;
    font-weight: bold;
    line-height: normal;
    padding: 1px;
    width: auto;
    margin-left: 4px;
    vertical-align: super;
}

.video-js .vjs-quality-button .vjs-hd-home {
    display: inline-table;
}

.video-js .vjs-volume-menu-button-horizontal .vjs-menu-content {
    height: 32px
}

.vjs-quality-button .vjs-menu .vjs-menu-content li {
    padding: 3px 25px;
    position: relative;
}

.vjs-quality-button span .vjs-hd-icon {
    vertical-align: top;
    margin-left: -5px;
    margin-top: 5px;
    border: 0;
    color: #fff;
    font-size: 8px;
    padding: 1px;
}

.vjs-audio .vjs-picture-in-picture-control, .vjs-audio vjs-menu-settings .vjs-menu-contrnt .vjs-extend-zoom {
    display: none;
}

.vjs-audio-info {
    position: absolute;
    display: flex;
    visibility: visible;
    opacity: 1;
    cursor: pointer;
    left: 0;
    top: 0;
    color: #fff;
    padding: 20px;
    font-size: 18px;
    font-family: sans-serif;
    font-weight: normal;
    text-align: left;
    text-shadow: 1px 1px 1px #000;
    max-width: 100%;

}

.vjs-480 .vjs-audio-info {
    padding: 10px;
}

.video-js .vjs-audio-info a {
    color: #fff;
    text-decoration: none;
    display: flex;
    max-width: 100%;
}

.vjs-audio-info a .vjs-audio-info .vjs-cover img {
    color: #fff;
}

.vjs-audio-info .vjs-cover {
    display: flex;
    margin-right: 15px;
    width: 164px;
    height: 164px;
}

.vjs-audio-info .vjs-cover img {
    padding: 2px;
    border: solid 1px #999;
    height: 100%;
    width: 100%;
}

.vjs-audio-info .vjs-text {
    flex: auto;
    overflow: hidden;
}

.vjs-audio-info .vjs-audio-item {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-shadow: 1px 1px 1px #000;
}

.vjs-audio-item .audio-artist {
    font-size: 20px;
    line-height: 1.5em;
    display: block;
}

.vjs-audio-item .vjs-song {
    font-size: 26px;
    line-height: 1.1em;
    display: block;
}

.vjs-audio-item .audio-id {
    display: block;
    margin-top: 15px;
    font-size: 16px;
    line-height: 1.5em;
}

.vjs-audio-item .audio-id span {
    display: block;
}

.vjs-480 .vjs-audio-info .vjs-cover {
    height: 104px;
    width: 104px;
}

.vjs-480 .vjs-audio-info .vjs-audio-item .audio-id {
    font-size: 12px;
    line-height: 1.5em;
    margin-top: 10px;
}

.vjs-480 .vjs-audio-info .vjs-audio-item .vjs-song {
    font-size: 18px;
}

.vjs-480 .vjs-audio-info .vjs-audio-item .audio-artist {
    font-size: 14px;
}


.vjs-1280 .vjs-audio-info .vjs-cover {
    height: 194px;
    width: 194px;
    margin-right: 20px;
}

.vjs-1280 .vjs-audio-item .audio-artist {
    font-size: 24px;
}

.vjs-1280 .vjs-audio-item .vjs-song {
    font-size: 32px;
}

.vjs-1280 .vjs-audio-item .audio-id {
    margin-top: 25px;
    font-size: 16px;
}

.vjs-1600 .vjs-audio-info {
    padding: 40px;
}

.vjs-1600 .vjs-audio-info .vjs-cover {
    height: 234px;
    width: 234px;
    margin-right: 25px;
}

.vjs-1600 .vjs-audio-item .audio-artist {
    font-size: 28px;
}

.vjs-1600 .vjs-audio-item .vjs-song {
    font-size: 38px;
}

.vjs-1600 .vjs-audio-item .audio-id {
    margin-top: 35px;
    font-size: 20px;
}

.vjs-modal-dialog .vjs-modal-dialog-content, .video-js .vjs-modal-dialog {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.vjs-modal-dialog .vjs-modal-dialog-content {
    font-size: 1.2em;
    line-height: 1.5em;
    padding: 20px 24px;
    z-index: 1;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #e5e5e5 #fff;
}


.video-js .vjs-text-track-settings {

    top: 50%;
    left: 50%;
    outline: 0;
    transform: translate(-50%, -50%);
    color: #111;
    margin: 0 auto;
    height: 90%;
    max-height: 260px;
    font-size: 12px;
    max-width: 400px;
    width: 90%;
    border-radius: 8px;
    z-index: 999;
    background-color: #fff;
}

.vjs-text-track-settings .vjs-modal-dialog-content {
    top: 0;
    bottom: 4em;
    left: 0;
    right: 0;
    position: absolute;
    overflow: auto;
    padding: 6px;
    height: auto;
}

.vjs-text-track-settings fieldset {
    padding: 8px;
    width: auto;
    border: 0;
    margin: 5px
}

.vjs-text-track-settings legend {
    font-size: 13px;
    color: #fff;
    margin: 0;
    display: inline-block;
    width: 100%;
    text-align: center;
    background-color: #222;
}


.vjs-text-track-settings select {
    padding: 6px;
    border: 1px solid #777;
    background: #fff;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 10px;
    font-size: 13px;
}

.vjs-text-track-settings .vjs-track-settings-controls {
    position: fixed;
    bottom: 10px;
    right: 1em;
}

.vjs-text-track-settings .vjs-close-button {
    display: none;
}

.vjs-text-track-settings .vjs-track-settings-controls button {
    cursor: pointer;
    width: auto;
    background: #e5e5e5;
    color: #121212;
    border-radius: 3px;
    margin: 0 3px;
    font-size: 12px;
    text-shadow: none;
    border: 0;
    padding: 3px 6px;
}

.vjs-text-track-settings .vjs-track-settings-controls button:hover {
    background: #b5b5b5;
}

.vjs-text-track-settings .vjs-tracksetting {
    margin: 5px;
    padding: 3px;
    min-height: 40px
}

.vjs-text-track-settings label {
    display: inline-block;
    width: 100px;
    margin: 0;
    padding: 0;
    padding-left: 15px;
    font-size: 12px;
}

.vjs-text-track-settings span {
    display: inline;
    margin-left: 5px
}

.vjs-text-track-settings > div {
    margin-bottom: 5px;
    min-height: 20px
}

.vjs-text-track-settings > div:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    min-height: 0
}

.vjs-caption-settings label > input {
    margin-right: 10px
}

.vjs-text-track-settings input[type=button] {
    width: 40px;
    height: 40px
}


.vjs-error .vjs-loading-spinner, .vjs-error .vjs-big-play-button {
    visibility: hidden;
}

.video-js .vjs-error-display .vjs-modal-dialog-content {
    font-size: 1.2rem;
}

.video-js .vjs-error-display .vjs-modal-dialog-description {
    display: none;
}

.video-js .vjs-error .vjs-error-display {
    display: block;
}

.video-js .vjs-modal-dialog {
    background-color: rgba(0, 0, 0, .66);
    overflow: hidden;
    z-index: 8;
}

.vjs-error-display {
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    text-align: center;
    width: 100%;
    outline: none;
    z-index: 7;
}

.vjs-error-display .vjs-modal-dialog-content {
    font-size: 1.2rem;
    text-align: center;
}

.vjs-error .vjs-error-display .vjs-modal-dialog-description {
    display: block;
    text-align: center;
}

.vjs-text-track-display {
    position: absolute;
    bottom: 4em;
    left: 0;
    right: 0;
    top: 0;
    pointer-events: none;
}

.video-js.vjs-user-inactive.vjs-playing .vjs-text-track-display {
    bottom: 1.5em
}

.video-js .vjs-text-track {
    font-size: 1.4em;
    text-align: center;
    margin-bottom: .1em;
}

.video-js .vjs-thumb-show {
    opacity: 1;
    transform: scale(1);
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

@-o-keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

.video-js .vjs-spacer {
    background-color: rgba(0, 0, 0, 0.4);
}


.vjs-live .vjs-progress-control {
    visibility: hidden;
    padding: 0;
}

.vjs-live .vjs-time-control {
    display: none;
}

.video-js .vjs-limit-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.88);
    z-index: 1999;
}

.video-js .vjs-limit {
    display: table;
    margin: 0 auto;
    color: #ccc;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: absolute;
    width: 75%;
    text-align: center;
    font-size: 1.4em;
    padding: 0 20px;
    text-shadow: 1px 1px 1px #000;
}

.video-js .vjs-limit span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.video-js .vjs-limit a {
    color: #fff;
    text-decoration: none;
}

.video-js .vjs-limit a:hover {
    text-decoration: underline;
}

.video-js .vjs-limit img {
    max-width: 100%;
    height: auto;
}


.video-js:not(.vjs-live) .vjs-live-control,
.video-js.vjs-liveui .vjs-live-control {
    display: none;
}

.video-js .vjs-seek-to-live-control {
    cursor: pointer;
    padding: 0 10px;
    width: auto;
    background: transparent;
    border: 0;
    color: #fff;
    outline: none;
    height: 32px;
    line-height: 30px;
    background-color: rgba(0, 0, 0, 0.4);
    border: solid 2px transparent;

}

.video-js .vjs-seek-to-live-control .vjs-icon-placeholder:before {
    content: '';
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #888;
    display: inline-block;
    margin-right: 6px;
}

.vjs-no-flex .vjs-seek-to-live-control {
    display: table-cell;
    width: auto;
    text-align: left;

}

.video-js.vjs-live:not(.vjs-liveui) .vjs-seek-to-live-control,
.video-js:not(.vjs-live) .vjs-seek-to-live-control {
    display: none;
}


.vjs-seek-to-live-control.vjs-control.vjs-at-live-edge {
    cursor: auto;
}

.vjs-seek-to-live-control .vjs-icon-placeholder {
    margin-right: 3px;
    color: #888;
}

.vjs-seek-to-live-control.vjs-control.vjs-at-live-edge .vjs-icon-placeholder:before {
    background-color: #ff0000;
}

.vjs-liveui .vjs-progress-control {
    display: flex;
    align-items: center;
    visibility: visible
}

.vjs-audio .vjs-control-bar {
    opacity: 1;
    visibility: visible;
}

.video-js .vjs-spacer {
    height: 32px;
    margin-top: 8px;
    background-color: rgba(0, 0, 0, 0.4);
}

.video-js .vjs-menu .vjs-menu-content .vjs-menu-title, .video-js .vjs-menu .vjs-menu-title, .video-js .vjs-menu-settings .vjs-menu-title {
    padding: 0 5px;
    text-align: center;
    display: block;
    font-size: 13px;
    text-transform: uppercase;
    background-color: #00b1ff;
    color: #fff;
    text-shadow: none;
    pointer-events: none;
}

.video-js .vjs-menu .vjs-menu-content .vjs-menu-title:hover {
    background-color: #00b1ff;
    color: #fff;
}

.vjs-480 .vjs-descriptions-button, .vjs-480 .vjs-audio-button, .vjs-480 .vjs-picture-in-picture-control, .vjs-480 .vjs-mode-control, .vjs-480 .vjs-control-text {
    display: none;
}

.vjs-480 .vjs-control-bar .vjs-button {
    width: 35px;
}

.vjs-480 .vjs-control-bar .vjs-quality-button {
    width: auto;
}

.vjs-480 .vjs-thumbnail-holder {
    bottom: 1.2em;
}

.vjs-480 .vjs-menu, .vjs-480 .vjs-menu-settings {
    bottom: 0;
    background-color: #222;
}

.vjs-480 .vjs-volume-panel .vjs-volume-horizontal {
    display: none;
}

.vjs-480 .vjs-caption-settings {
    width: 90%;
    height: 90%;
}

.vjs-480 .vjs-spacer {
    display: flex;
    flex: auto;
    text-align: left;
}


.vjs-480 .vjs-control-bar .vjs-progress-control {
    position: absolute;
    top: -8px;
    height: 16px;
    left: 43px;
    width: calc(100% - 43px);
    margin: 0;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
}

.vjs-480 .vjs-progress-holder {
    height: 16px;
}

.vjs-480 .vjs-progress-holder:before, .vjs-480 .vjs-progress-control .vjs-load-progress, .vjs-480 .vjs-progress-control .vjs-play-progress {
    top: 9px;
    height: 3px;
    border: 0;
}


.vjs-480 .vjs-marker {
    top: 9px;
}

.vjs-480 .vjs-marker-inn {
    height: 3px;
}

.vjs-480 .vjs-live .vjs-time-control {
    display: none;
}

.video-js .vjs-nonlinear {
    position: absolute;
    bottom: 10px;
    max-width: 90%;
    max-height: 80%;
    background: transparent;
    transform: translateX(-50%);
    left: 50%;
    transition: bottom .5s ease, opacity .5s ease;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
    z-index: 2147483647;
}

.video-js .vjs-nonlinear img {
    width: 100%;
    height: 100%;
    border: 0;
}

.video-js.vjs-user-active .vjs-nonlinear, .video-js.vjs-paused:not(.vjs-touch-inactive) .vjs-nonlinear, .video-js.vjs-user-active .vjs-nonlinear-recall, .video-js.vjs-paused:not(.vjs-touch-inactive) .vjs-nonlinear-recall {
    bottom: 60px;
}

.vjs-480.vjs-user-active .vjs-nonlinear, .vjs-480.vjs-paused:not(.vjs-touch-inactive) .vjs-nonlinear, .vjs-480.vjs-user-active .vjs-nonlinear-recall, .vjs-480.vjs-paused:not(.vjs-touch-inactive) .vjs-nonlinear-recall {
    bottom: 55px;
}

.vjs-1600.vjs-user-active .vjs-nonlinear, .vjs-1600.vjs-paused:not(.vjs-touch-inactive) .vjs-nonlinear, .vjs-1600.vjs-user-active .vjs-nonlinear-recall, .vjs-1600.vjs-paused:not(.vjs-touch-inactive) .vjs-nonlinear-recall {
    bottom: 100px;
}

.vjs-nonlinear .nonlinear-close {
    position: absolute;
    right: 3px;
    top: 3px;
    z-index: 55;
    width: 17px;
    height: 17px;
    stroke: #666;
    fill: #666;
    stroke-width: 1.25;
    cursor: pointer;
    background-color: #e5e5e5;
    border-style: outset;
    border-width: 1px;

}

.vjs-nonlinear-recall {
    position: absolute;
    right: 390px;
    width: 24px;
    height: 12px;
    background: rgba(200, 200, 200, .5);
    border: solid 1px rgba(255, 255, 255, .5);
    border-bottom: 0;
    bottom: 10px;
    left: 50%;
    margin-left: -12px;
    cursor: pointer;
}

.vjs-nonlinear-recall:hover {
    background-color: #fff;
    border: solid 1px rgba(0, 0, 0, .5);
    border-bottom: 0;
}

@media print {
    .video-js > *:not(.vjs-tech):not(.vjs-poster) {
        visibility: hidden;
    }
}

.js-focus-visible .video-js *:focus:not(.focus-visible) {
    outline: none;
}

.video-js *:focus:not(:focus-visible),
.video-js .vjs-menu *:focus:not(:focus-visible) {
    outline: none;
}

.vjs-label-hidden {
    display: none;
}

.vjs-iab .vjs-control-bar {
    visibility: hidden;
    opacity: 0;
}


.vjs-black-poster {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    cursor: pointer;
    background-color: #000;
}

.vjs-has-started .vjs-black-poster.vjs-hidden {
    display: none;
}

.vjs-using-native-controls .vjs-black-poster {
    display: none;
}

.VPAID-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.vjs-resize-manager {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    visibility: hidden
}

.vjs-chromeless.vjs-has-started .vjs-control-bar,
.vjs-chromeless.vjs-has-started .vjs-bg-bar,
.vjs-chromeless.vjs-has-started .vjs-big-play-button,
.vjs-chromeless.vjs-has-started .vjs-loading-spinner {
    display: none;
    width: 0;
    height: 0;
    visibility: hidden;
    pointer-events: none;
}

.vjs-ended:not(.vjs-vr) .vjs-control-bar,
.vjs-ended .vjs-control-text {
    display: none;
}

.vjs-ended.vjs-vr .vjs-control-bar {
    opacity: 1;
    visibility: visible;
}

.vjs-1600:not(.vjs-cast-fixed) .vjs-control-bar {
    height: 50px;
}

.vjs-1600 .vjs-control-bar .vjs-button {
    line-height: 50px;
    width: 42px;
    height: 42px;
}

.vjs-1600 .vjs-control-bar .vjs-progress-control {
    height: 42px;
}

.vjs-1600 .vjs-time-control {
    line-height: 42px;
    height: 42px;
    font-size: 15px;
}

.vjs-1600 .vjs-volume-panel .vjs-volume-horizontal .vjs-slider-horizontal {
    line-height: 2.8;
}

.vjs-1600 .vjs-play-control {
    height: 50px;
    line-height: 50px;
    width: 60px;
}

.vjs-1600 .vjs-progress-holder:before,
.vjs-1600 .vjs-progress-control .vjs-load-progress, .vjs-1600 .vjs-progress-control .vjs-play-progress {
    top: 18px;
}

.vjs-1600 .vjs-control-bar .vjs-quality-button {
    width: auto;
    line-height: 44px;
}

.vjs-1600 .vjs-quality-button span {
    font-size: 14px;
}

.vjs-1600 .vjs-volume-panel .vjs-volume-horizontal {
    height: 42px;
    line-height: 42px;
}

.vjs-1600 .vjs-volume-panel .vjs-volume-horizontal .vjs-slider-horizontal:before,
.vjs-1600 .vjs-volume-panel .vjs-volume-level:before {
    font-size: 18px;
}

.vjs-1600 .vjs-live-control, .vjs-1600 .vjs-seek-to-live-control {
    font-size: 14px;
    height: 42px;
    line-height: 42px;
}

.vjs-1600 .vjs-marker {
    top: 18px;
}

.vjs-1600 .vjs-control-bar .vjs-menu {
    bottom: 42px;
}

.vjs-1600 .vjs-text-track-display {
    bottom: 7em;
}

.video-js .vjs-hidden {
    display: none !important;
}

.vjs-controls-none .vjs-control-bar {
    display: none;
}

.vjs-bp-block {
    display: block !important;
}

.video-js .vjs-abs-hidden {
    display: none !important;
}

.video-js *:focus:not(:focus-visible) {
    outline: none;
}

.video-js:not(.vjs-touch-enabled) *:focus:focus-visible {
    border: solid 2px #c5c5c5 !important;
    border-radius: 4px;
}

.video-js:not(.vjs-touch-enabled) .vjs-zoom-menu .vjs-zoom-reset:focus-visible {
    border: 0 !important;
    outline-style: solid !important;
    outline-color: #c5c5c5 !important;;
    outline-offset: -2px !important;
    outline-width: 2px;
}

.video-js:not(.vjs-touch-enabled) .vjs-tech:focus-visible {
    border: 0 !important;
}

.video-js:not(.vjs-touch-enabled) .vjs-control-bar .vjs-control:focus-visible,
.video-js:not(.vjs-touch-enabled) .vjs-control-bar .vjs-progress-control:focus-visible {
    border: solid 2px #c5c5c5 !important;
    border-radius: 4px;
}

.video-js:not(.vjs-touch-enabled) .vjs-volume-panel .vjs-volume-horizontal .vjs-slider-horizontal:focus-visible {
    border: 0 !important;
    outline-style: solid !important;
    outline-color: #c5c5c5 !important;;
    outline-offset: 0 !important;
    outline-width: 2px;
}
